import { unref as _unref, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, createBlock as _createBlock } from "vue";
const _hoisted_1 = {
  style: {
    "min-height": "1000px"
  }
};
import { onMounted, ref, watch } from 'vue';
import { useRoute } from 'vue-router';
import { VuePdf, createLoadingTask } from 'vue3-pdfjs';
import { ElLoading } from 'element-plus';
export default {
  __name: 'Pdf',
  setup(__props) {
    const route = useRoute();
    let pdfUrl = ref(`https://res.carbonhp.com/assetbundles/carbon/${route.params.code}.pdf`);
    // let pdfUrl = ref(`/api/${route.params.code}.pdf`)
    // let pageNumber = ref(1)
    let pageTotalNum = ref(0);
    const options = ref({
      scale: 1
    });
    const handlePageRendered = e => {};
    watch(route, (newValue, oldValue) => {
      location.reload();
    });
    onMounted(() => {
      const loadingTask = createLoadingTask(pdfUrl.value);
      loadingTask.promise.then(pdf => {
        pageTotalNum.value = pdf.numPages;
      });
    });
    return (_ctx, _cache) => {
      return _openBlock(), _createElementBlock("div", _hoisted_1, [(_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_unref(pageTotalNum), page => {
        return _openBlock(), _createBlock(_unref(VuePdf), {
          key: page,
          src: _unref(pdfUrl),
          page: page,
          options: options.value,
          onPageRendered: handlePageRendered
        }, null, 8, ["src", "page", "options"]);
      }), 128))]);
    };
  }
};